import { useEffect, useState } from "react";

const useResultadosPor = () => {
  const [isLoading, setLoading] = useState(true);
  const [resultadosPor, setResultadosPor] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const json = [
          { cod_resultado: "porTotal", nom_resultado: "Total Nacional" },
          { cod_resultado: "porDistrito", nom_resultado: "Por distrito" },
          { cod_resultado: "porSeccion", nom_resultado: "Por sección" },
          { cod_resultado: "porCircuito", nom_resultado: "Por circuito" },
          { cod_resultado: "porMesa", nom_resultado: "Por mesa" },
        ];
        console.log(json);
        // @ts-ignore
        setResultadosPor(Array.isArray(json) ? json : []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { isLoadingResultadosPor: isLoading, resultadosPor };
};
export default useResultadosPor;
