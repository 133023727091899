import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const useCircuitos = (eleccion: string, distrito: string, seccion: string) => {
  const [isLoading, setLoading] = useState(true);
  const [circuitos, setCircuitos] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        const params = {
          eleccion: eleccion,
          distrito: distrito,
          seccion: seccion,
        };
        const res = await fetch(
          `${AppConfig.API_URL}/circuitos?${convertToQueryString(params)}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        // @ts-ignore
        setCircuitos(Array.isArray(json) ? json : []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [eleccion, distrito, seccion]);

  return { isLoadingCircuitos: isLoading, circuitos };
};
export default useCircuitos;
