import { Select } from "antd";
import useClae6 from "../../hooks/establecimientos/useClae6";

const { Option } = Select;

const Clae6 = (props) => {
  const { clae6, isLoadingClae6 } = useClae6();

  const handleChangeClae6 = (value) => {
    // console.log(`Clae6 handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 400, marginBottom: 40 }}
      placeholder="Seleccionar Clae6"
      value={props.clae6}
      loading={isLoadingClae6}
      onChange={handleChangeClae6}
    >
      <Option value="" />
      {clae6.map((clae6) => (
        <Option key={clae6.cod_clae6}>{clae6.nom_clae6}</Option>
      ))}
    </Select>
  );
};

export default Clae6;
