import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ConfigProvider, Layout, Typography, theme } from "antd";
import "antd/dist/reset.css";
import "./index.css";
import HeaderPrincipal from "./components/HeaderPrincipal";
import FooterPrincipal from "./components/FooterPrincipal";
import EleccionesApp from "./EleccionesApp";
import EstablecimientosApp from "./EstablecimientosApp";
import CensoPApp from "./CensoPApp";
import PerfilesApp from "./PerfilesApp";
import reportWebVitals from "./reportWebVitals";

const CUSTOM_THEME = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: "#d0021b",
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <ConfigProvider theme={CUSTOM_THEME}>
        <Layout style={{ minHeight: "100vh" }}>
          <Layout.Content>
            <Routes>
              <Route
                path="*"
                element={<Typography.Text>Página no encontrada</Typography.Text>}
              />
              <Route path="/" element={<EleccionesApp />} />
              <Route
                path="/establecimientos"
                element={<EstablecimientosApp />}
              />
              <Route path="/censop" element={<CensoPApp />} />
              <Route path="/perfiles" element={<PerfilesApp />} />
            </Routes>
          </Layout.Content>
        </Layout>
      </ConfigProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
