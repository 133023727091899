import { useState } from 'react';
import { Button, Modal, Table } from "antd";
import { CSVLink } from "react-csv";

import MapaDistritos from "./MapaDistritos";

import useResultadosTotales from "../../../hooks/elecciones/useResultadosTotales";
import useResultadosDistritos from "../../../hooks/elecciones/useResultadosDistritos";
import useResultadosSecciones from "../../../hooks/elecciones/useResultadosSecciones";
import useResultadosCircuitos from "../../../hooks/elecciones/useResultadosCircuitos";
import useResultadosMesas from "../../../hooks/elecciones/useResultadosMesas";

const hooksPorConsulta = {
  porTotal: useResultadosTotales,
  porDistrito: useResultadosDistritos,
  porSeccion: useResultadosSecciones,
  porCircuito: useResultadosCircuitos,
  porMesa: useResultadosMesas,
};

function getColumns(resultadoPor, resultadoListasPASO) {
  const columns = [];

  // vamos insertando en orden inverso, las diferentes columnas para poder ir
  // sumando una categoria a medida que incrementamos granularidad en la consulta
  switch (resultadoPor) {
    case "porMesa":
      columns.unshift({
        title: "Mesa",
        dataIndex: "nom_mesa",
        key: "cod_mesa",
      });
    case "porCircuito":
      columns.unshift({
        title: "Circuito",
        dataIndex: "nom_circuito",
        key: "cod_circuito",
      });
    case "porSeccion":
      columns.unshift({
        title: "Sección",
        dataIndex: "nom_seccion",
        key: "cod_seccion",
      });
    case "porDistrito":
      columns.unshift({
        title: "Distrito",
        dataIndex: "nom_distrito",
        key: "cod_distrito",
      });
    case "porTotal":
      columns.unshift({
        title: "Categoría",
        dataIndex: "nom_categoria",
        key: "cod_categoria",
      });
      break;
    default:
      throw new Error("Agrupamiento no reconocido.");
  }

  columns.push(
    { title: "Lista", dataIndex: "cod_agrupacion", key: "cod_agrupacion" },
    {
      title: "Nombre de Lista",
      dataIndex: "nom_agrupacion",
      key: "nom_agrupacion",
    }
  );

  if (resultadoListasPASO) {
    columns.push(
      { title: "SubLista", dataIndex: "cod_lista", key: "cod_lista" },
      { title: "Nombre de SubLista", dataIndex: "nom_lista", key: "nom_lista" }
    );
  }

  columns.push(
    { title: "Votos", dataIndex: "votos", key: "votos" },
    { title: "Porcentaje", dataIndex: "porcentaje", key: "porcentaje" }
  );

  return columns;
}

function hasMap(resultadoPor: string, categoria: string, isLoading: boolean): boolean {
  return resultadoPor === 'porDistrito' && categoria === '01' && !isLoading;
}

export default function ListaResultadosBuilder(props) {
  const { resultadoPor, resultadoListasPASO } = props;

  const useResultados = hooksPorConsulta[resultadoPor];
  const [resultados, isLoading] = useResultados(props);

  const [showMapModal, setShowMapModal] = useState(false);
  const showMapData = hasMap(resultadoPor, props.categoria, isLoading);

  return (
    <>
      {showMapData && (
        <Modal
          centered
          open={showMapModal}
          onOk={() => setShowMapModal(false)}
          onCancel={() => setShowMapModal(false)}
          width={'auto'}
        >
          <MapaDistritos data={resultados} agrupacion={props.agrupacion} />
        </Modal>
      )}

      <div style={{ marginBottom: "12px", textAlign: "right" }}>
        {showMapData && (
          <Button
            style={{ marginRight: "8px" }}
            type="primary"
            onClick={() => setShowMapModal(true)}
          >
            Mostrar mapa
          </Button>
        )}
        <Button type="primary">
          <CSVLink
            filename={`Resultados${resultadoPor
              .charAt(0)
              .toUpperCase()}${resultadoPor.slice(1)}.csv`}
            data={resultados}
          >
            Exportar a CSV
          </CSVLink>
        </Button>
      </div>

      <Table
        loading={isLoading}
        dataSource={resultados}
        columns={getColumns(resultadoPor, resultadoListasPASO)}
      />
    </>
  );
}
