import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

// lets unhardcode this
const COLORSCALE = {
  '0087': 'Greys',
  '0131': 'Greys',
  '0004': 'Blues',
  '0135': 'Hot',
  '0136': 'Blues',
  '0137': 'Greys',
};

const MapaDistritos = ({ data, agrupacion }) => {
  const [geoJsonDistritos, setGeoJsonDistritos] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(`${process.env.PUBLIC_URL}/distritos.geojson`);

      setGeoJsonDistritos(await result.json());
    };

    fetchData();
  }, []);

  if (!geoJsonDistritos) {
    return null;
  }

  return (
    <Plot
      debug={true}
      layout={{
        title: 'Mapa por distrito',
        autosize: true,
        paper_bgcolor: '#000000',
        plot_bgcolor: '#000000',
        margin: {
          t: 50,
          b: 50,
          l: 30,
          r: 30,
        },
        width: 1024,
        height: 620,
        showlegend: false,
        mapbox: {
          style: 'white-bg',
          center: { lat: -40, lon: -65 },
          zoom: 3,
        },
        ...(agrupacion ? {
          colorscale: {
            sequential: COLORSCALE[agrupacion],
          },
        } : null),
      }}
      data={
        [{
          type: 'choroplethmapbox',
          geojson: geoJsonDistritos,
          locationmode: 'geojson-id',
          locations: geoJsonDistritos.features.map(({ properties }) => properties.nombre_dis),
          z: geoJsonDistritos.features.map(({ properties }) => {
            if (agrupacion) {
              const distrito = data.find((distrito) => distrito.cod_distrito === properties.codigo_dis);

              if (distrito) {
                return distrito.porcentaje;
              }

              return 0;
            }

            // winner view TBD
            return Math.random() * 10;
          }),
          featureidkey: 'properties.nombre_dis',
          autocolorscale: !!agrupacion,
        }]
      }
    />
  );
};

export default MapaDistritos;
