import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const useEleccionesCircuitos = (
  eleccion: string,
  categoria: string,
  distrito: string,
  seccion: string,
  circuito: string,
  perfil: string
) => {
  const [isLoading, setLoading] = useState(true);
  const [EleccionesCircuitos, setEleccionesCircuitos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        const params = {
          eleccion: eleccion,
          categoria: categoria,
          distrito: distrito,
          seccion: seccion,
          circuito: circuito,
          perfil: perfil,
        };
        const res = await fetch(
          `${
            AppConfig.API_URL
          }/perfiles/eleccionesCircuitosPerfiles?${convertToQueryString(
            params
          )}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        setEleccionesCircuitos(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [eleccion, categoria, distrito, seccion, circuito, perfil]);

  return { isLoadingEleccionesCircuitos: isLoading, EleccionesCircuitos };
};
export default useEleccionesCircuitos;
