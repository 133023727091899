import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const useResultadosCensoP = ({
  clae2,
  clae6,
  claeLetra,
  rango,
  provincia,
  departamento,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [resultados, setResultados] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        if (!provincia && !departamento) {
          return [];
        }
        const params = {
          base: "d2022_censo_provisorios",
          provincia: provincia,
          departamento: departamento,
        };
        const res = await fetch(
          `${AppConfig.API_URL}/${"censop_censo?"}${convertToQueryString(
            params
          )}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        setResultados(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [provincia, departamento]);

  return [resultados, isLoading];
};
export default useResultadosCensoP;
