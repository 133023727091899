import { Select } from "antd";
import useDistritos from "../../hooks/elecciones/useDistritos";

const { Option } = Select;

const Distritos = (props) => {
  const { distritos, isLoadingDistritos } = useDistritos(
    props.eleccion,
    props.categoria
  );

  const handleChangeDistrito = (value) => {
    // console.log(`Distritos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 250, marginBottom: 40 }}
      placeholder="Seleccionar distrito"
      loading={isLoadingDistritos}
      onChange={handleChangeDistrito}
    >
      <Option value="" />
      {distritos.map((distrito) => (
        <Option key={distrito.cod_distrito}>{distrito.nom_distrito}</Option>
      ))}
    </Select>
  );
};

export default Distritos;
