import { Select } from "antd";
import useDistritosCircuitos from "../../hooks/perfiles/useDistritosCircuitos";

const { Option } = Select;

const DistritosCircuitos = (props) => {
  console.log("DistritosCircuitos props: ", props);
  const { DistritosCircuitos, isLoadingDistritosCircuitos } =
    useDistritosCircuitos(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.circuito,
      props.perfil
    );

  const handleChangeDistritosCircuitos = (value) => {
    console.log(`DistritosCircuitos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Distritos"
      value={props.distrito}
      loading={isLoadingDistritosCircuitos}
      onChange={handleChangeDistritosCircuitos}
      allowClear
    >
      <Option value="" />
      {DistritosCircuitos.map((distrito) => (
        <Option key={distrito.cod_distrito} value={distrito.cod_distrito}>
          {distrito.nom_distrito}
        </Option>
      ))}
    </Select>
  );
};

export default DistritosCircuitos;
