import { Select } from "antd";
import useProvincias from "../../hooks/establecimientos/useProvincias";

const { Option } = Select;

const Provincias = (props) => {
  const { provincias, isLoadingProvincias } = useProvincias();

  const handleChangeProvincias = (value) => {
    // console.log(`Provincias handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 150, marginBottom: 40 }}
      placeholder="Provincia"
      value={props.provincia}
      loading={isLoadingProvincias}
      onChange={handleChangeProvincias}
    >
      <Option value="" />
      {provincias.map((provincia) => (
        <Option key={provincia.cod_provincia}>{provincia.nom_provincia}</Option>
      ))}
    </Select>
  );
};

export default Provincias;