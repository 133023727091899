import { Table, Button } from "antd";
import { CSVLink } from "react-csv";
import useResultadosPerfilesCircuitos from "../../hooks/perfiles/useResultadosPerfilesCircuitos";

const ListaResultadosPerfilesCircuitos = (props) => {
  console.log("ListaResultadosPerfilesCircuitos props: ", props);
  const [resultados, isLoading] = useResultadosPerfilesCircuitos(props);

  const columns = [
    { title: "Elección", dataIndex: "nom_eleccion", key: "nom_eleccion" },
    { title: "Categoría", dataIndex: "nom_categoria", key: "nom_categoria" },
    { title: "Cod Distrito", dataIndex: "cod_distrito", key: "cod_distrito" },
    { title: "Distrito", dataIndex: "nom_distrito", key: "nom_distrito" },
    { title: "Cod Sección", dataIndex: "cod_seccion", key: "cod_seccion" },
    { title: "Sección", dataIndex: "nom_seccion", key: "nom_seccion" },
    { title: "Circuito", dataIndex: "cod_circuito", key: "cod_circuito" },
    { title: "Perfil", dataIndex: "perfil", key: "perfil" },
  ];

  return (
    <>
      <div style={{ marginBottom: "12px", textAlign: "right" }}>
        <Button type="primary">
          <CSVLink
            filename={`Resultados_Perfiles_Circuitos.csv`}
            data={resultados}
            headers={columns.map((col) => ({
              label: col.title,
              key: col.dataIndex,
            }))}
          >
            Exportar a CSV
          </CSVLink>
        </Button>
      </div>

      <Table loading={isLoading} dataSource={resultados} columns={columns} />
    </>
  );
};

export default ListaResultadosPerfilesCircuitos;
