import { Select } from "antd";
import useClae2 from "../../hooks/establecimientos/useClae2";

const { Option } = Select;

const Clae2 = (props) => {
  const { clae2, isLoadingClae2 } = useClae2();

  const handleChangeClae2 = (value) => {
    // console.log(`Clae2 handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 400, marginBottom: 40 }}
      placeholder="Seleccionar Clae2"
      value={props.clae2}
      loading={isLoadingClae2}
      onChange={handleChangeClae2}
    >
      <Option value="" />
      {clae2.map((clae2) => (
        <Option key={clae2.cod_clae2}>{clae2.nom_clae2}</Option>
      ))}
    </Select>
  );
};

export default Clae2;
