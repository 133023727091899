import { Table } from "antd";
import useResultadosEstablecimientos from "../../hooks/establecimientos/useResultadosEstablecimientos";

const ListaResultadosEstablecimientos = (props) => {
  const [resultados, isLoading] = useResultadosEstablecimientos(props);

  const columns = [
    { title: "Provincia", dataIndex: "nom_provincia", key: "cod_provincia" },
    { title: "Departamento", dataIndex: "nom_departamento", key: "cod_departamento" },
    { title: "Letra", dataIndex: "nom_letra", key: "cod_letra" },
    { title: "Clae2", dataIndex: "nom_clae2", key: "cod_clae2" },
    { title: "Clae6", dataIndex: "nom_clae6", key: "cod_clae6" },
    { title: "Rango", dataIndex: "nom_rango", key: "cod_rango" },
    { title: "Latitud", dataIndex: "latitud", key: "latitud" },
    { title: "Longitud", dataIndex: "longitud", key: "longitud" },
  ];

  return (
    <Table
      loading={isLoading}
      dataSource={resultados}
      columns={columns}
    />
  );
};

export default ListaResultadosEstablecimientos;
