import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";

const useElecciones = () => {
  const [isLoading, setLoading] = useState(true);
  const [elecciones, setElecciones] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        const res = await fetch(`${AppConfig.API_URL}/elecciones`, {
          headers: myHeaders,
        });
        const json = await res.json();
        setElecciones(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { isLoadingElecciones: isLoading, elecciones };
};
export default useElecciones;
