import { Select } from "antd";
import useCircuitosCircuitos from "../../hooks/perfiles/useCircuitosCircuitos";

const { Option } = Select;

const CircuitosCircuitos = (props) => {
  console.log("CircuitosCircuitos props: ", props);
  const { CircuitosCircuitos, isLoadingCircuitosCircuitos } =
    useCircuitosCircuitos(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.circuito,
      props.perfil
    );

  const handleChangeCircuitosCircuitos = (value) => {
    console.log(`CircuitosCircuitos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Circuitos"
      value={props.circuito}
      loading={isLoadingCircuitosCircuitos}
      onChange={handleChangeCircuitosCircuitos}
      allowClear
    >
      <Option value="" />
      {CircuitosCircuitos.map((circuito) => (
        <Option key={circuito.cod_circuito} value={circuito.cod_circuito}>
          {circuito.nom_circuito}
        </Option>
      ))}
    </Select>
  );
};

export default CircuitosCircuitos;
