import { useState } from "react";
import Clae2 from "./components/establecimientos/Clae2";
import Clae6 from "./components/establecimientos/Clae6";
import ClaeLetra from "./components/establecimientos/ClaeLetra";
import Rangos from "./components/establecimientos/Rangos";
import Provincias from "./components/establecimientos/Provincias";
import Departamentos from "./components/establecimientos/Departamentos";
import ListaResultadosEstablecimientos from "./components/establecimientos/ListaResultadosEstablecimientos";

export default function EstablecimientosApp() {
  const [clae2, setClae2] = useState();
  const [clae6, setClae6] = useState();
  const [claeLetra, setClaeLetra] = useState();
  const [rango, setRango] = useState();
  const [provincia, setProvincia] = useState();
  const [departamento, setDepartamento] = useState();

  return (
    <div className="container">
      <div style={{ marginTop: 30, marginLeft: 20 }}>
        <Provincias onChange={setProvincia} />
        <Departamentos onChange={setDepartamento} provincia={provincia} />
        <Rangos onChange={setRango} />
        <ClaeLetra onChange={setClaeLetra} />
        <Clae2 onChange={setClae2} />
        <Clae6 onChange={setClae6} />
      </div>

      <ListaResultadosEstablecimientos
        clae2={clae2}
        clae6={clae6}
        claeLetra={claeLetra}
        rango={rango}
        provincia={provincia}
        departamento={departamento}
      />
    </div>
  );
}
