import { Select } from "antd";
import useDepartamentos from "../../hooks/establecimientos/useDepartamentos";

const { Option } = Select;

const Departamentos = (props) => {
  const { departamentos, isLoadingDepartamentos } = useDepartamentos(
    props.provincia
  );

  const handleChangeDepartamentos = (value) => {
    // console.log(`Departamentos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 150, marginBottom: 40 }}
      placeholder="Departamento"
      value={props.departamento}
      loading={isLoadingDepartamentos}
      onChange={handleChangeDepartamentos}
    >
      <Option value="" />
      {departamentos.map((departamento) => (
        <Option key={departamento.cod_departamento}>
          {departamento.nom_departamento}
        </Option>
      ))}
    </Select>
  );
};

export default Departamentos;
