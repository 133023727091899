import { Select } from "antd";
import useRangos from "../../hooks/establecimientos/useRangos";

const { Option } = Select;

const Rangos = (props) => {
  const { rangos, isLoadingRangos } = useRangos();

  const handleChangeRangos = (value) => {
    // console.log(`Rangos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 100, marginBottom: 40 }}
      placeholder="Rango"
      value={props.rango}
      loading={isLoadingRangos}
      onChange={handleChangeRangos}
    >
      <Option value="" />
      {rangos.map((rango) => (
        <Option key={rango.cod_rango}>{rango.nom_rango}</Option>
      ))}
    </Select>
  );
};

export default Rangos;
