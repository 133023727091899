import { Checkbox } from "antd";

const ResultadosListasPASO = (props) => {
  const { resultadoListasPASO, isLoadingResultadosListasPASO } = false;

  const handleChangeResultadosListasPASO = (ev) => {
    props.onChange(ev.target.checked);
  };

  return (
    <Checkbox
      style={{ width: "50%" }}
      value={props.resultadoListasPASO}
      loading={isLoadingResultadosListasPASO}
      onChange={handleChangeResultadosListasPASO}
    >
      Listas PASO
    </Checkbox>
  );
};

export default ResultadosListasPASO;
