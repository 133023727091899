import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";

const useResultadosSeccionesMesas = ({
  eleccion,
  categoria,
  distrito,
  seccion,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [resultados, setResultados] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        let url = `${AppConfig.API_URL}/resultadosSeccionesMesas`;
        if (eleccion) url = url + `?eleccion=` + eleccion;
        if (eleccion && categoria) url = url + `&categoria=` + categoria;
        else if (categoria) url = url + `?categoria=` + categoria;
        if ((eleccion || categoria) && distrito)
          url = url + `&distrito=` + distrito;
        else if (distrito) url = url + `?distrito=` + distrito;
        if (seccion) url = url + `&seccion=` + seccion;
        console.log(url);
        const res = await fetch(url, { headers: myHeaders });
        const json = await res.json();
        console.log(json);
        setResultados(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [eleccion, categoria, distrito, seccion]);

  return [resultados, isLoading];
};
export default useResultadosSeccionesMesas;
