import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const useDepartamentos = (provincia: string) => {
  const [isLoading, setLoading] = useState(true);
  const [departamentos, setDepartamentos] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        const params = {
          base: "d2022_censo_provisorios",
          provincia: provincia,
        };
        //let url = `${AppConfig.API_URL}/censop_departamentos?base=d2022_censo_provisorios`;
        //const res = await fetch(url, { headers: myHeaders });
        const res = await fetch(
          `${AppConfig.API_URL}/${"censop_departamentos?"}${convertToQueryString(
            params
          )}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        //console.log(json);
        setDepartamentos(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [provincia]);

  return { isLoadingDepartamentos: isLoading, departamentos };
};
export default useDepartamentos;
