import { useState } from "react";
import Provincias from "./components/censop/Provincias";
import Departamentos from "./components/censop/Departamentos";
import ListaResultadosCensoP from "./components/censop/ListaResultadosCensoP";

export default function CensoPApp() {
  const [provincia, setProvincia] = useState();
  const [departamento, setDepartamento] = useState();

  return (
    <div className="container">
      <div style={{ marginTop: 30, marginLeft: 20 }}>
        <Provincias onChange={setProvincia} />
        <Departamentos onChange={setDepartamento} provincia={provincia} />
      </div>

      <ListaResultadosCensoP
        provincia={provincia}
        departamento={departamento}
      />
    </div>
  );
}
