import { Select } from "antd";
import useEleccionesSecciones from "../../hooks/perfiles/useEleccionesSecciones";

const { Option } = Select;

const EleccionesSecciones = (props) => {
  console.log("EleccionesSecciones props: ", props);
  const { eleccionesSecciones, isLoadingEleccionesSecciones } =
    useEleccionesSecciones(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.perfil
    );

  const handleChangeEleccionesSecciones = (value) => {
    console.log(`EleccionesSecciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Elecciones"
      value={props.eleccion}
      loading={isLoadingEleccionesSecciones}
      onChange={handleChangeEleccionesSecciones}
      allowClear
    >
      <Option value="" />
      {eleccionesSecciones.map((eleccion) => (
        <Option key={eleccion.cod_eleccion} value={eleccion.cod_eleccion}>
          {eleccion.nom_eleccion}
        </Option>
      ))}
    </Select>
  );
};

export default EleccionesSecciones;
