import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";

const useClaeLetra = () => {
  const [isLoading, setLoading] = useState(true);
  const [claeLetra, setClaeLetra] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        let url = `${AppConfig.API_URL}/claeLetra?base=d2022_establecimientos`;
        const res = await fetch(url, { headers: myHeaders });
        const json = await res.json();
        console.log(json);
        setClaeLetra(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { isLoadingClaeLetra: isLoading, claeLetra };
};
export default useClaeLetra;
