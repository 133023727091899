import { useState } from "react";
import Elecciones from "./components/elecciones/Elecciones";
import Categorias from "./components/elecciones/Categorias";
import Distritos from "./components/elecciones/Distritos";
import Secciones from "./components/elecciones/Secciones";
import Circuitos from "./components/elecciones/Circuitos";
import Mesas from "./components/elecciones/Mesas";
import Agrupaciones from "./components/elecciones/Agrupaciones";
import ListaResultados from "./components/elecciones/resultados/ListaResultados";
import ResultadosPor from "./components/elecciones/ResultadosPor";
import ResultadosListasPASO from "./components/elecciones/ResultadosListasPASO";

export default function EleccionesApp() {
  const [eleccion, setEleccion] = useState("e2023_2da_vuelta");
  const [categoria, setCategoria] = useState();
  const [distrito, setDistrito] = useState();
  const [seccion, setSeccion] = useState();
  const [circuito, setCircuito] = useState();
  const [mesa, setMesa] = useState();
  const [agrupacion, setAgrupacion] = useState();
  const [resultadoPor, setResultadoPor] = useState("porTotal");
  const [resultadoListasPASO, setResultadoListasPASO] = useState(false);

  return (
    <div className="container">
      <div style={{ marginTop: 30, marginLeft: 20 }}>
        <ResultadosPor onChange={setResultadoPor} />
        {(eleccion == "e2015_paso" ||
          eleccion == "e2017_paso" ||
          eleccion == "e2019_paso" ||
          eleccion == "e2021_paso" ||
          eleccion == "e2023_paso") && (
          <ResultadosListasPASO onChange={setResultadoListasPASO} />
        )}
      </div>

      <div style={{ marginTop: 30, marginLeft: 20 }}>
        <Elecciones onChange={setEleccion} />
        <Categorias
          onChange={setCategoria}
          eleccion={eleccion}
          distrito={distrito}
        />
        {(resultadoPor == "porDistrito" ||
          resultadoPor == "porSeccion" ||
          resultadoPor == "porCircuito" ||
          resultadoPor == "porMesa") && (
          <Distritos
            onChange={setDistrito}
            eleccion={eleccion}
            categoria={categoria}
          />
        )}
        {(resultadoPor == "porSeccion" ||
          resultadoPor == "porCircuito" ||
          resultadoPor == "porMesa") &&
          distrito && (
            <Secciones
              onChange={setSeccion}
              eleccion={eleccion}
              distrito={distrito}
            />
          )}
        {(resultadoPor == "porCircuito" || resultadoPor == "porMesa") &&
          distrito && (
            <Circuitos
              onChange={setCircuito}
              eleccion={eleccion}
              distrito={distrito}
              seccion={seccion}
            />
          )}
        {resultadoPor == "porMesa" && distrito && seccion && (
          <Mesas
            onChange={setMesa}
            eleccion={eleccion}
            distrito={distrito}
            seccion={seccion}
            circuito={circuito}
          />
        )}
        {((resultadoPor == "porDistrito" && categoria == "01") ||
          (resultadoPor == "porDistrito" && distrito) ||
          (resultadoPor == "porSeccion" && distrito) ||
          (resultadoPor == "porCircuito" && distrito) ||
          (resultadoPor == "porMesa" && distrito)) && (
          <Agrupaciones
            onChange={setAgrupacion}
            eleccion={eleccion}
            distrito={distrito}
            categoria={categoria}
          />
        )}
      </div>

      <ListaResultados
        eleccion={eleccion}
        categoria={categoria}
        distrito={distrito}
        seccion={seccion}
        circuito={circuito}
        mesa={mesa}
        agrupacion={agrupacion}
        resultadoPor={resultadoPor}
        resultadoListasPASO={resultadoListasPASO}
      />
    </div>
  );
}
