import { Radio } from "antd";
import useResultadosPor from "../../hooks/elecciones/useResultadosPor";

const ResultadosPor = (props) => {
  const { resultadosPor, isLoadingResultadosPor } = useResultadosPor();

  const handleChangeResultadoPor = (ev) => {
    props.onChange(ev.target.value);
  };

  return (
    <Radio.Group
      style={{ width: "50%" }}
      defaultValue="porTotal"
      loading={isLoadingResultadosPor}
      onChange={handleChangeResultadoPor}
    >
      {resultadosPor.map((resultadoPor) => (
        <Radio.Button
          key={resultadoPor.cod_resultado}
          value={resultadoPor.cod_resultado}
        >
          {resultadoPor.nom_resultado}
        </Radio.Button>
      ))}
      Resultados Por
    </Radio.Group>
  );
};

export default ResultadosPor;
