import { Select } from "antd";
import useElecciones from "../../hooks/elecciones/useElecciones";

const { Option } = Select;

const Elecciones = (props) => {
  const { elecciones, isLoadingElecciones } = useElecciones();

  const handleChangeEleccion = (value) => {
    // console.log(`Elecciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Elecciones 2023 Generales"
      value={props.eleccion}
      loading={isLoadingElecciones}
      onChange={handleChangeEleccion}
    >
      {elecciones.map((eleccion) => (
        <Option key={eleccion.cod_eleccion}>{eleccion.nom_eleccion}</Option>
      ))}
    </Select>
  );
};

export default Elecciones;
