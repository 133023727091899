import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";

const useClae6 = () => {
  const [isLoading, setLoading] = useState(true);
  const [clae6, setClae6] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        let url = `${AppConfig.API_URL}/clae6?base=d2022_establecimientos`;
        const res = await fetch(url, { headers: myHeaders });
        const json = await res.json();
        console.log(json);
        setClae6(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { isLoadingClae6: isLoading, clae6 };
};
export default useClae6;
