import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const useResultadosMesas = ({
  resultadoPor,
  resultadoListasPASO,
  eleccion,
  categoria,
  agrupacion,
  distrito,
  seccion,
  circuito,
  mesa,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [resultados, setResultados] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        const params = {
          eleccion: eleccion,
          categoria: categoria,
          agrupacion: agrupacion,
          distrito: distrito,
          seccion: seccion,
          circuito: circuito,
          mesa: mesa,
        };
        const res = await fetch(
          `${AppConfig.API_URL}/${
            resultadoListasPASO
              ? "resultadosMesasSublistas?"
              : "resultadosMesas?"
          }${convertToQueryString(params)}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        setResultados(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [
    resultadoPor,
    resultadoListasPASO,
    eleccion,
    categoria,
    agrupacion,
    distrito,
    seccion,
    circuito,
    mesa,
  ]);

  return [resultados, isLoading];
};
export default useResultadosMesas;
