import { Select } from "antd";
import useMesas from "../../hooks/elecciones/useMesas";

const { Option } = Select;

const Mesas = (props) => {
  const { mesas, isLoadingMesas } = useMesas(
    props.eleccion,
    props.distrito,
    props.seccion,
    props.circuito
  );

  const handleChangeMesa = (value) => {
    // console.log(`Mesas handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 250, marginBottom: 40 }}
      placeholder="Seleccionar mesa"
      loading={isLoadingMesas}
      onChange={handleChangeMesa}
    >
      <Option value="" />
      {mesas.map((mesa) => (
        <Option key={mesa.cod_mesa}>{mesa.nom_mesa}</Option>
      ))}
    </Select>
  );
};

export default Mesas;
