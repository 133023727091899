import { Table } from "antd";

import useResultadosTotalesMesas from "../../../hooks/elecciones/useResultadosTotalesMesas";
import useResultadosDistritosMesas from "../../../hooks/elecciones/useResultadosDistritosMesas";
import useResultadosSeccionesMesas from "../../../hooks/elecciones/useResultadosSeccionesMesas";
import useResultadosCircuitosMesas from "../../../hooks/elecciones/useResultadosCircuitosMesas";
import useResultadosMesasMesas from "../../../hooks/elecciones/useResultadosMesasMesa";

const hooksPorConsulta = {
  porTotal: useResultadosTotalesMesas,
  porDistrito: useResultadosDistritosMesas,
  porSeccion: useResultadosSeccionesMesas,
  porCircuito: useResultadosCircuitosMesas,
  porMesa: useResultadosMesasMesas,
};

function getColumns(resultadoPor) {
  const columns = [];

  // vamos insertando en orden inverso, las diferentes columnas para poder ir
  // sumando una categoria a medida que incrementamos granularidad en la consulta
  switch (resultadoPor) {
    case "porMesa":
      columns.unshift({
        title: "Mesa",
        dataIndex: "nom_mesa",
        key: "cod_mesa",
      });
    case "porCircuito":
      columns.unshift({
        title: "Circuito",
        dataIndex: "nom_circuito",
        key: "cod_circuito",
      });
    case "porSeccion":
      columns.unshift({
        title: "Sección",
        dataIndex: "nom_seccion",
        key: "cod_seccion",
      });
    case "porDistrito":
      columns.unshift({
        title: "Distrito",
        dataIndex: "nom_distrito",
        key: "cod_distrito",
      });
    case "porTotal":
      columns.unshift({
        title: "Categoría",
        dataIndex: "nom_categoria",
        key: "cod_categoria",
      });
      break;
    default:
      throw new Error("Agrupamiento no reconocido.");
  }

  columns.push(
    { title: "Cant. Mesas", dataIndex: "cant_mesas", key: "cant_mesas" },
    {
      title: "Cant. Electores",
      dataIndex: "cant_electores",
      key: "cant_electores",
    },
    { title: "Votos Totales", dataIndex: "votos", key: "votos" },
    {
      title: "Votos Positivos",
      dataIndex: "votos_positivos",
      key: "votos_positivos",
    }
  );

  return columns;
}

export default function ListaResultadosMesasBuilder(props) {
  const { resultadoPor } = props;
  const useResultados = hooksPorConsulta[resultadoPor];
  const [resultados, isLoading] = useResultados(props);

  return (
    <Table
      loading={isLoading}
      dataSource={resultados}
      columns={getColumns(resultadoPor)}
    />
  );
}
