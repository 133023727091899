import { Select } from "antd";
import useDistritosSecciones from "../../hooks/perfiles/useDistritosSecciones";

const { Option } = Select;

const DistritosSecciones = (props) => {
  console.log("DistritosSecciones props: ", props);
  const { distritosSecciones, isLoadingDistritosSecciones } =
    useDistritosSecciones(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.perfil
    );

  const handleChangeDistritosSecciones = (value) => {
    console.log(`DistritosSecciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Distritos"
      value={props.distrito}
      loading={isLoadingDistritosSecciones}
      onChange={handleChangeDistritosSecciones}
      allowClear
    >
      <Option value="" />
      {distritosSecciones.map((distrito) => (
        <Option key={distrito.cod_distrito} value={distrito.cod_distrito}>
          {distrito.nom_distrito}
        </Option>
      ))}
    </Select>
  );
};

export default DistritosSecciones;
