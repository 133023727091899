import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const useSecciones = (eleccion: string, distrito: string) => {
  const [isLoading, setLoading] = useState(true);
  const [secciones, setSecciones] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        const params = {
          eleccion: eleccion,
          distrito: distrito,
        };
        const res = await fetch(
          `${AppConfig.API_URL}/secciones?${convertToQueryString(params)}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        // @ts-ignore
        setSecciones(Array.isArray(json) ? json : []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [eleccion, distrito]);

  return { isLoadingSecciones: isLoading, secciones };
};
export default useSecciones;
