import { Table } from "antd";
import useResultadosCensoP from "../../hooks/censop/useResultadosCensoP";

const ListaResultadosCensoP = (props) => {
  const [resultados, isLoading] = useResultadosCensoP(props);

  const columns = [
    { title: "Provincia", dataIndex: "nom_provincia", key: "cod_provincia" },
    { title: "Departamento", dataIndex: "nom_departamento", key: "cod_departamento" },
    { title: "Viviendas particulares", dataIndex: "viviendas_particulares", key: "viviendas_particulares" },
    { title: "Viviendas colectivas", dataIndex: "viviendas_colectivas", key: "viviendas_colectivas" },
    { title: "Población en viviendas particulares", dataIndex: "poblacion_viviendas_particulares", key: "poblacion_viviendas_particulares" },
    { title: "Población en viviendas colectivas", dataIndex: "poblacion_viviendas_colectivas", key: "poblacion_viviendas_colectivas" },
    { title: "Población en sit. de calle", dataIndex: "poblacion_situacion_calle", key: "poblacion_situacion_calle" },
    { title: "Mujeres", dataIndex: "mujeres", key: "mujeres" },
    { title: "Varones", dataIndex: "varones", key: "varones" },
    { title: "Género X", dataIndex: "generox", key: "generox" },
  ];

  return (
    <Table
      loading={isLoading}
      dataSource={resultados}
      columns={columns}
    />
  );
};

export default ListaResultadosCensoP;
