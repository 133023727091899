import { Select } from "antd";
import useCategorias from "../../hooks/elecciones/useCategorias";

const { Option } = Select;

const Categorias = (props) => {
  const { categorias, isLoadingCategorias } = useCategorias(
    props.eleccion,
    props.distrito
  );

  const handleChangeCategoria = (value) => {
    // console.log(`Categorias handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Seleccionar categoría"
      loading={isLoadingCategorias}
      onChange={handleChangeCategoria}
    >
      <Option value="" />
      {categorias.map((categoria) => (
        <Option key={categoria.cod_categoria}>{categoria.nom_categoria}</Option>
      ))}
    </Select>
  );
};

export default Categorias;
