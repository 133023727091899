
import { useState } from "react";
import EleccionesSecciones from "./components/perfiles/EleccionesSecciones";
import CategoriasSecciones from "./components/perfiles/CategoriasSecciones";
import DistritosSecciones from "./components/perfiles/DistritosSecciones";
import SeccionesSecciones from "./components/perfiles/SeccionesSecciones";
import PerfilesSecciones from "./components/perfiles/PerfilesSecciones";
import ListaResultadosPerfilesSecciones from "./components/perfiles/ListaResultadosPerfilesSecciones";

import EleccionesCircuitos from "./components/perfiles/EleccionesCircuitos";
import CategoriasCircuitos from "./components/perfiles/CategoriasCircuitos";
import DistritosCircuitos from "./components/perfiles/DistritosCircuitos";
import SeccionesCircuitos from "./components/perfiles/SeccionesCircuitos";
import CircuitosCircuitos from "./components/perfiles/CircuitosCircuitos";
import PerfilesCircuitos from "./components/perfiles/PerfilesCircuitos";
import ListaResultadosPerfilesCircuitos from "./components/perfiles/ListaResultadosPerfilesCircuitos";

export default function PerfilesApp() {
  const [eleccion, setEleccion] = useState();
  const [categoria, setCategoria] = useState();
  const [distrito, setDistrito] = useState();
  const [seccion, setSeccion] = useState();
  const [perfil, setPerfil] = useState();

  const [porCircuito, setPorCircuito] = useState(false);

  const [eleccionCircuito, setEleccionCircuito] = useState();
  const [categoriaCircuito, setCategoriaCircuito] = useState();
  const [distritoCircuito, setDistritoCircuito] = useState();
  const [seccionCircuito, setSeccionCircuito] = useState();
  const [circuitoCircuito, setCircuitoCircuito] = useState();
  const [perfilCircuito, setPerfilCircuito] = useState();

  return (
    <div className="container">
      <div style={{ marginTop: 30, marginLeft: 20 }}>
        {porCircuito ? (
          <>
            <EleccionesCircuitos
              onChange={setEleccionCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              seccion={seccionCircuito}
              perfil={perfilCircuito}
            />
            <CategoriasCircuitos
              onChange={setCategoriaCircuito}
              categoria={categoriaCircuito}
              eleccion={eleccionCircuito}
            />
            <DistritosCircuitos
              onChange={setDistritoCircuito}
              distrito={distritoCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
            />
            <SeccionesCircuitos
              onChange={setSeccionCircuito}
              seccion={seccionCircuito}
              distrito={distritoCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
            />
            <CircuitosCircuitos
              onChange={setCircuitoCircuito}
              circuito={circuitoCircuito}
              seccion={seccionCircuito}
              distrito={distritoCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
            />
            <PerfilesCircuitos
              onChange={setPerfilCircuito}
              perfil={perfilCircuito}
              seccion={seccionCircuito}
              distrito={distritoCircuito}
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
            />
            <label style={{ marginLeft: 20, fontSize: 16, fontWeight: 'bold', color: '#333' }}>
              <input type="checkbox" checked={porCircuito} onChange={(e) => setPorCircuito(e.target.checked)} style={{ marginRight: 8 }} />
              Por Circuito
            </label>
            <ListaResultadosPerfilesCircuitos
              eleccion={eleccionCircuito}
              categoria={categoriaCircuito}
              distrito={distritoCircuito}
              seccion={seccionCircuito}
              perfil={perfilCircuito}
            />
          </>
        ) : (
          <>
            <EleccionesSecciones
              onChange={setEleccion}
              eleccion={eleccion}
              categoria={categoria}
              distrito={distrito}
              seccion={seccion}
              perfil={perfil}
            />
            <CategoriasSecciones
              onChange={setCategoria}
              categoria={categoria}
              eleccion={eleccion}
            />
            <DistritosSecciones
              onChange={setDistrito}
              distrito={distrito}
              eleccion={eleccion}
              categoria={categoria}
            />
            <SeccionesSecciones
              onChange={setSeccion}
              seccion={seccion}
              distrito={distrito}
              eleccion={eleccion}
              categoria={categoria}
            />
            <PerfilesSecciones
              onChange={setPerfil}
              perfil={perfil}
              seccion={seccion}
              distrito={distrito}
              eleccion={eleccion}
              categoria={categoria}
            />
            <label style={{ marginLeft: 20, fontSize: 16, fontWeight: 'bold', color: '#333' }}>
              <input type="checkbox" checked={porCircuito} onChange={(e) => setPorCircuito(e.target.checked)} style={{ marginRight: 8 }} />
              Por Circuito
            </label>
            <ListaResultadosPerfilesSecciones
              eleccion={eleccion}
              categoria={categoria}
              distrito={distrito}
              seccion={seccion}
              perfil={perfil}
            />
          </>
        )}

      </div>
    </div>
  );
}
