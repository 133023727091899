import { Select } from "antd";
import useSecciones from "../../hooks/elecciones/useSecciones";

const { Option } = Select;

const Secciones = (props) => {
  const { secciones, isLoadingSecciones } = useSecciones(
    props.eleccion,
    props.distrito
  );

  const handleChangeSeccion = (value) => {
    // console.log(`Secciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Seleccionar sección"
      loading={isLoadingSecciones}
      onChange={handleChangeSeccion}
    >
      <Option value="" />
      {secciones.map((seccion) => (
        <Option key={seccion.cod_seccion}>{seccion.nom_seccion}</Option>
      ))}
    </Select>
  );
};

export default Secciones;
