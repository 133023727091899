import { Select } from "antd";
import useAgrupaciones from "../../hooks/elecciones/useAgrupaciones";

const { Option } = Select;

const Agrupaciones = (props) => {
  const { agrupaciones, isLoadingAgrupaciones } = useAgrupaciones(
    props.eleccion,
    props.distrito,
    props.categoria
  );

  const handleChangeAgrupacion = (value) => {
    // console.log(`Agrupaciones handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 450, marginBottom: 40 }}
      placeholder="Seleccionar agrupacion"
      loading={isLoadingAgrupaciones}
      onChange={handleChangeAgrupacion}
    >
      <Option value="" />
      {agrupaciones.map((agrupacion) => (
        <Option key={agrupacion.cod_agrupacion}>
          {agrupacion.cod_agrupacion} - {agrupacion.nom_agrupacion}
        </Option>
      ))}
    </Select>
  );
};

export default Agrupaciones;
