import { Select } from "antd";
import useCircuitos from "../../hooks/elecciones/useCircuitos";

const { Option } = Select;

const Circuitos = (props) => {
  const { circuitos, isLoadingCircuitos } = useCircuitos(
    props.eleccion,
    props.distrito,
    props.seccion
  );

  const handleChangeCircuito = (value) => {
    // console.log(`Circuitos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 200, marginBottom: 40 }}
      placeholder="Seleccionar circuito"
      loading={isLoadingCircuitos}
      onChange={handleChangeCircuito}
    >
      <Option value="" />
      {circuitos.map((circuito) => (
        <Option key={circuito.cod_circuito}>{circuito.nom_circuito}</Option>
      ))}
    </Select>
  );
};

export default Circuitos;
