import { Select } from "antd";
import useSeccionesCircuitos from "../../hooks/perfiles/useSeccionesCircuitos";

const { Option } = Select;

const SeccionesCircuitos = (props) => {
  console.log("SeccionesCircuitos props: ", props);
  const { SeccionesCircuitos, isLoadingSeccionesCircuitos } =
    useSeccionesCircuitos(
      props.eleccion,
      props.categoria,
      props.distrito,
      props.seccion,
      props.circuito,
      props.perfil
    );

  const handleChangeSeccionesCircuitos = (value) => {
    console.log(`SeccionesCircuitos handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 225, marginBottom: 40 }}
      placeholder="Secciones"
      value={props.seccion}
      loading={isLoadingSeccionesCircuitos}
      onChange={handleChangeSeccionesCircuitos}
      allowClear
    >
      <Option value="" />
      {SeccionesCircuitos.map((seccion) => (
        <Option key={seccion.cod_seccion} value={seccion.cod_seccion}>
          {seccion.nom_seccion}
        </Option>
      ))}
    </Select>
  );
};

export default SeccionesCircuitos;
