import { Select } from "antd";
import useClaeLetra from "../../hooks/establecimientos/useClaeLetra";

const { Option } = Select;

const ClaeLetra = (props) => {
  const { claeLetra, isLoadingClaeLetra } = useClaeLetra();

  const handleChangeClaeLetra = (value) => {
    // console.log(`ClaeLetra handleChange ${value}`);
    props.onChange(value);
  };

  return (
    <Select
      style={{ width: 400, marginBottom: 40 }}
      placeholder="Seleccionar ClaeLetra"
      value={props.claeLetra}
      loading={isLoadingClaeLetra}
      onChange={handleChangeClaeLetra}
    >
      <Option value="" />
      {claeLetra.map((claeLetra) => (
        <Option key={claeLetra.cod_letra}>{claeLetra.nom_letra}</Option>
      ))}
    </Select>
  );
};

export default ClaeLetra;
