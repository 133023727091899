import { useEffect, useState } from "react";
import AppConfig from "../../AppConfig";
import { convertToQueryString } from "../utils";

const useDistritos = (eleccion: string, categoria: string) => {
  const [isLoading, setLoading] = useState(true);
  const [distritos, setDistritos] = useState<[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("ngrok-skip-browser-warning", "1");
        const params = {
          eleccion: eleccion,
          categoria: categoria,
        };
        const res = await fetch(
          `${AppConfig.API_URL}/distritos?${convertToQueryString(params)}`,
          {
            headers: myHeaders,
          }
        );
        const json = await res.json();
        setDistritos(json);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [eleccion, categoria]);

  return { isLoadingDistritos: isLoading, distritos };
};
export default useDistritos;
