import TablaResultados from "./TablaResultados";
import TablaResultadosMesas from "./TablaResultadosMesas";

export default function ListaResultados(props) {
  const { resultadoPor, categoria } = props;

  if (resultadoPor === "porTotal" && categoria !== "01") {
    return null;
  }

  return (
    <>
      <div>
        <TablaResultados {...props} />
      </div>
      <div>
        <TablaResultadosMesas {...props} />
      </div>
    </>
  );
}
